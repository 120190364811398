import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { Config } from 'utils/initialConfig'
import sentryJSON from './sentry-release'
i18next.init({
  interpolation: { escapeValue: false } // React already does escaping
})

Sentry.init({
  dsn: Config.REACT_APP_SENTRY_DSN,
  integrations: (integrations) => {
    let filtered = integrations.filter(function (integration) {
      return integration.name !== 'TryCatch' && integration !== 'BrowserTracing'
    })
    return [
      ...filtered,
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
      // Sentry.replayIntegration(),
    ]
  },
  environment: Config.REACT_APP_SENTRY_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(Config.REACT_APP_SENTRY_SAMPLE_RATE),
  release: sentryJSON.release
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
