import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import UsersPage from 'pages/admin/users/UsersPage'
import AdminTemplate from 'components/templates/AdminTemplate'
import ProfilePage from 'pages/admin/profile/ProfilePage'
import ContactsPage from 'pages/admin/contacts/ContactsPage'
import CompaniesPage from 'pages/admin/companies/CompaniesPage'
import UsersDetailPage from 'pages/admin/users/UsersDetailPage'
import CompanyDetailPage from 'pages/admin/companies/CompanyDetailPage'
import PrivacyPolicyPage from 'pages/admin/privacy-policy/PrivacyPolicyPage'
import TermsConditionsPage from 'pages/admin/terms-conditions/TermsConditionsPage'
import UserTemplate from 'components/templates/UserTemplate'
import Workspace from 'pages/user/workspace/Workspace'
import CasesPage from 'pages/user/case/CasesPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import RequireAuth from './RequireAuth'
import LoginPage from 'pages/auth/LoginPage'
import ProfilePlatformPage from 'pages/user/profile/ProfilePlatformPage'
import ChatPage from 'pages/user/chats/ChatPage'
import RegulationPage from 'pages/user/regulation/RegulationPage'
import DocumentPage from 'pages/user/documents/DocumentPage'
import SentryErrorPage from 'pages/SentryErrorPage'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import { useAuth } from 'hooks'
import { ROLE_SUPER_ADMIN } from 'constant'

const Router = () => {
  const selectedLang = useSelector((state) => state.lang.selected)
  const { getRoleUser } = useAuth()

  useEffect(() => {
    i18next.changeLanguage(selectedLang)
  }, [selectedLang])

  return (
    <Routes>
      <Route path="/test-sentry-error" element={<SentryErrorPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin/login" element={<LoginPage />} />
      <Route path="/reset-password/" element={<ResetPasswordPage />} />
      <Route path="/admin/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/admin/"
        element={
          <RequireAuth isBackOffice={true}>
            <AdminTemplate />
          </RequireAuth>
        }>
        {getRoleUser() === ROLE_SUPER_ADMIN && <Route index element={<CompaniesPage />} />}
        {getRoleUser() !== ROLE_SUPER_ADMIN && <Route index element={<CompanyDetailPage />} />}
        <Route path="users" element={<UsersPage />} />
        <Route path="users/:id" element={<UsersDetailPage />} />
        <Route path="profile" element={<ProfilePage />} />
        {getRoleUser() === ROLE_SUPER_ADMIN && (
          <Route path="companies" element={<CompaniesPage />} />
        )}
        {getRoleUser() !== ROLE_SUPER_ADMIN && (
          <Route path="companies" element={<CompanyDetailPage />} />
        )}
        {getRoleUser() === ROLE_SUPER_ADMIN && <Route path="contacts" element={<ContactsPage />} />}
        {getRoleUser() === ROLE_SUPER_ADMIN && (
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        )}
        {getRoleUser() === ROLE_SUPER_ADMIN && (
          <Route path="terms-conditions" element={<TermsConditionsPage />} />
        )}
        <Route path="companies/:id" element={<CompanyDetailPage />} />
        <Route path="/admin/*" element={<Navigate to="/admin" />} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth isBackOffice={false}>
            <UserTemplate />
          </RequireAuth>
        }>
        <Route index element={<Workspace />} />
        <Route path="cases" element={<Workspace />} />
        <Route path="cases/:id" element={<CasesPage />} />
        <Route path="cases/:caseId/chat/:id" element={<ChatPage />} />
        <Route path="cases/:caseId/documents/:id" element={<DocumentPage />} />
        <Route path="profile" element={<ProfilePlatformPage />} />
        <Route path="security" element={<ProfilePlatformPage />} />
        <Route path="contacts" element={<ProfilePlatformPage />} />
        <Route path="regulations" element={<RegulationPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export default Router
