import React from 'react'

const SentryErrorPage = () => {
  onclick = () => {
    return test.data.data
  }
  return (
    <div>
      <button
        onClick={onclick}
        style={{ margin: 30, height: 40, width: 60, backgroundColor: 'red', color: 'white' }}>
        test error{' '}
      </button>
    </div>
  )
}

export default SentryErrorPage
