import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Typography, ConfigProvider, Select, Popover } from 'antd'
import {
  AccountCircle,
  Add,
  Balance,
  CalendarToday,
  DataUsage,
  FilterListOutlined,
  Gavel,
  KeyboardArrowDown,
  MoreVertOutlined,
  Numbers,
  PersonOutline,
  Search
} from '@mui/icons-material'
import { Breadcrumbs, Tab, Tabs, Tooltip } from '@mui/material'
import ListChat from 'components/organisms/users/chat/ListChat'
import ListDocument from 'components/organisms/users/document/ListDocument'

import CustomButton from 'components/atoms/CustomButton'
import ChatFilterModal from 'components/organisms/users/chat/ChatFilterModal'
import { INITIAL_CHAT_FILTER, ROLE_ADMIN, ROLE_USER } from 'constant'
import { showSuccessMessage, showErrorMessage } from 'utils/showMessage'
import RenameModal from 'components/organisms/users/RenameModal'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { errorObj } from 'utils/handleError'

import {
  getDetailCase,
  getChatListDetailCase,
  getStatusListDetailCase,
  updateStatusDetailCase,
  getDocumentListDetailCase,
  updateChatNameDetailCase,
  updateDocumentNameDetailCase,
  getRegulationList,
  updateCase,
  postNewChats,
  deleteChat,
  deleteDocument,
  retryDocument
} from 'api'
import { useSelector } from 'react-redux'
import moment from 'moment'
import useAuth from 'hooks/useAuth'
import ExportNotesModal from 'components/organisms/users/ExportNotesModal'
import DeleteCaseModal from 'components/organisms/users/DeleteCaseModal'
import DeleteCaseDetailTableItem from 'components/organisms/users/DeleteCaseDetailTableItem'
import { useDeleteCase, useExportNotes } from 'hooks'
import NewChatModal from 'components/organisms/users/chat/NewChatModal'
import AddDocumentModal from 'components/organisms/users/document/AddDocumentModal'
import CaseModal from 'components/organisms/users/cases/CaseModal'
import SearchInput from 'components/atoms/SearchInput'
import { debounce } from 'lodash'
import useExportAsDocx from 'hooks/useExportAsDocx'
import { useTranslation } from 'react-i18next'

const initialTableState = {
  total: 0,
  page: 1,
  limit: 20,
  search: '',
  sort: 'created_at'
}
const initialFilterState = {
  role: undefined,
  typeDate: undefined,
  startDate: undefined,
  endDate: undefined
}

const CasesPage = () => {
  const { t } = useTranslation()
  const { handleExportDocument } = useExportAsDocx()
  const [modalCase, setModalCase] = useState({
    show: false,
    type: 'edit'
  })
  const navigate = useNavigate()
  const { platformData } = useAuth()
  const {
    exportNotesMutation,
    exportNotesModal,
    openExportNotesModal,
    closeExportNotesModal,
    handleExportNotes
  } = useExportNotes()
  const {
    deleteCaseMutation,
    deleteCaseModal,
    openDeleteCaseModal,
    closeDeleteCaseModal,
    handleDeleteCase
  } = useDeleteCase(navigateBackToWorkspace)
  const [layoutInfo] = useOutletContext()
  const [infoHeaderLayout, setInfoHeaderLayout] = useState(0)
  const infoHeaderRef = useRef()
  const [tableState, setTableState] = useState(initialTableState)
  const [filterState, setFilterState] = useState(initialFilterState)

  const [seeMore, setSeeMore] = useState()
  const [tabTable, setTabTable] = useState(0)
  const [tabNote, setTabNote] = useState(0)

  const [filterChatModal, setFilterChatModal] = useState(INITIAL_CHAT_FILTER)
  const [renameModal, setRenameModal] = useState({
    title: '',
    show: false,
    onClick: () => {}
  })
  const [chatList, setChatList] = useState([])
  const [documentList, setDocumentList] = useState([])
  const [firstClient, setFirstClient] = useState()
  const [moreClient, setMoreClient] = useState([])
  const [firstCounterpart, setFirstCounterpart] = useState()
  const [moreCounterpart, setMoreCounterpart] = useState([])
  const [statusList, setStatusList] = useState([])
  const [statusValue, setStatusValue] = useState(null)
  const [chatId, setChatId] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [dataRename, setDataRename] = useState(null)
  const [detailData, setDetailData] = useState({})
  const [newChatModal, setNewChatModal] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [documentModal, setDocumentModal] = useState({
    show: false,
    files: []
  })
  const [imageStatus, setImageStatus] = useState({})
  const [showDeleteTable, setShowDeleteTable] = useState(false)
  const [deleteItem, setDeleteItem] = useState('')
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [filterDataType, setFilterDataType] = useState('chat')

  const uploadRef = useRef()

  const getFilterCount = () => {
    if (filterState.startDate && filterState.endDate) {
      return 2
    } else if (!filterState.startDate && filterState.endDate) {
      return 1
    } else if (filterState.startDate && !filterState.endDate) {
      return 1
    } else {
      return 0
    }
  }

  function navigateBackToWorkspace() {
    return navigate('/cases', { replace: true })
  }
  function openNewChatModal() {
    setNewChatModal(true)
  }
  function closeNewChatModal() {
    setNewChatModal(false)
  }
  const openSearchBox = () => {
    setOpenSearch(true)
  }
  function navigateToChat(chatData) {
    navigate(`/cases/${detailData?.id}/chat/${chatData?.id}`, {
      state: { caseId: detailData?.id, caseName: detailData?.name, chatData: chatData }
    })
  }

  const confirmNewChatMutationFromDocument = useMutation({
    mutationKey: '',
    mutationFn: (selectedDocumentIds) => {
      return postNewChats(token, detailData?.id, selectedDocumentIds)
    },
    onSuccess: (res) => {
      navigate(`/cases/${detailData?.id}/chat/${res.resData.data.id}`, {
        state: {
          caseId: detailData?.id,
          caseName: detailData?.name,
          chatData: res.resData.data
        }
      })
    },
    onError: () => {
      showErrorMessage('Error create new chat, please try again.')
    }
  })

  const goToTheChatFromDocument = (row) => {
    confirmNewChatMutationFromDocument.mutate([row.id])
  }

  function navigateToDoc(docData) {
    navigate(`/cases/${detailData?.id}/documents/${docData?.id}`, {
      state: { caseId: detailData?.id, caseName: detailData?.name, document: docData }
    })
  }

  const params = useParams()
  const caseId = params?.id
  const token = useSelector((state) => state.user.access_token)
  const queryClient = useQueryClient()

  function openFilterChatModal() {
    setFilterChatModal((prev) => ({ ...prev, show: true }))
    if (tabTable === 0) {
      setFilterDataType('chat')
    } else {
      setFilterDataType('document')
    }
  }
  function closeFilterChatModal() {
    setFilterChatModal((prev) => ({
      ...prev,
      show: false,
      data: { ...INITIAL_CHAT_FILTER.data }
    }))
  }
  function handleSubmitFilter(data) {
    setFilterChatModal((prev) => ({ ...prev, data: data }))
    setFilterState((prev) => ({
      ...prev,
      startDate: data.fromDate,
      endDate: data.toDate,
      typeDate: 'between'
    }))
  }

  const cancelFilter = () => {
    setFilterChatModal((prev) => ({
      ...prev,
      show: false,
      data: { ...INITIAL_CHAT_FILTER.data }
    }))
    setFilterState(initialFilterState)
  }
  function openRenameChatModal(row) {
    setChatId(row.id)
    setDataRename(row)
    setRenameModal((prev) => ({
      ...prev,
      title: t('Rename chat'),
      show: true,
      onClick: handleSubmitRenameChat
    }))
  }

  function openRenameDocumentModal(row) {
    setDocumentId(row.id)
    const filename = row?.filename.split('.')
    let name = filename.join('.')
    if (filename.length > 1) {
      name = filename.slice(0, filename.length - 1).join('.')
    }
    let newRow = {
      ...row,
      name: name
    }
    setDataRename(newRow)
    setRenameModal((prev) => ({
      ...prev,
      title: 'Rename document',
      show: true,
      onClick: (data) =>
        handleSubmitRenameDocument({
          renameValue: `${data.renameValue}.${filename[filename.length - 1]}`
        })
    }))
  }

  async function onRetryDocument(row) {
    retryingDoc.mutate(row)
  }

  function closeRenameModal() {
    setRenameModal((prev) => ({ ...prev, show: false }))
  }
  // passing props to get data.renameValue in renameChat mutation
  const renameChat = useMutation({
    mutationKey: ['rename-chat'],
    mutationFn: async (data) => await updateChatNameDetailCase(token, chatId, data.renameValue),
    onSuccess: () => {
      showSuccessMessage('Chat renamed!')
      queryClient.invalidateQueries(['data-list'])
    }
  })
  const retryingDoc = useMutation({
    mutationKey: ['retry-document'],
    mutationFn: async (data) => await retryDocument(token, data?.id, detailData?.id),
    onSuccess: () => {
      showSuccessMessage('Retry Document!')
      queryClient.invalidateQueries(['data-list'])
    }
  })

  const deletingChat = useMutation({
    mutationKey: ['delete-chat'],
    mutationFn: async (id) => await deleteChat(token, id),
    onSuccess: () => {
      showSuccessMessage('Chat deleted!')
      queryClient.invalidateQueries(['data-list'])
    }
  })

  const deletingDocument = useMutation({
    mutationKey: ['delete-document'],
    mutationFn: async (id) => await deleteDocument(token, id),
    onSuccess: () => {
      showSuccessMessage('Document deleted!')
      queryClient.invalidateQueries(['data-list'])
    }
  })

  const handleOpenDeleteTableChat = (row) => {
    setDeleteItemId(row.id)
    setDeleteItem('chat')
    setShowDeleteTable(true)
  }

  const handleOpenDeleteTableDocument = (row) => {
    setDeleteItemId(row.id)
    setDeleteItem('document')
    setShowDeleteTable(true)
  }
  const handleCloseDeleteTable = () => {
    setShowDeleteTable(false)
    setDeleteItemId(null)
    setDeleteItem('')
  }

  const handleDeleteTable = () => {
    if (deleteItem === 'chat') {
      deletingChat.mutate(deleteItemId)
    } else if (deleteItem === 'document') {
      deletingDocument.mutate(deleteItemId)
    }
    setShowDeleteTable(false)
  }

  const handleDownloadDocument = async (row) => {
    window.open(row.filepath, '_blank')
  }

  const renameDocument = useMutation({
    mutationKey: ['rename-document'],
    mutationFn: async (data) =>
      await updateDocumentNameDetailCase(token, documentId, data.renameValue),
    onSuccess: () => {
      showSuccessMessage('Document renamed!')
      queryClient.invalidateQueries(['data-list'])
    }
  })

  const { refetch } = useQuery({
    queryKey: ['case-detail'],
    refetchOnWindowFocus: false,
    queryFn: async () => await getDetailCase(token, caseId),
    onSuccess: (data) => {
      setStatusValue(data.resData.data.case_status_id)
      setDetailData(data.resData.data)
      setSeeMore(false)
      // data.resData.data.case_clients.map((client, index) => {
      //   if (index === 0) {
      //     setFirstClient(client)
      //   } else if (index !== 0) {
      //     let moreData = []
      //     moreData.push(client)
      //     setMoreClient(moreData)
      //   }
      // })

      if (data?.resData?.data?.case_clients?.length > 1) {
        setFirstClient(data.resData.data.case_clients[0])
        setMoreClient(data.resData.data.case_clients.slice(1))
      } else {
        setFirstClient(data.resData.data.case_clients[0])
        setMoreClient([])
      }

      // data.resData.data.case_counterparts.map((counterpart, index) => {
      //   if (index === 0) {
      //     setFirstCounterpart(counterpart)
      //   } else {
      //     setMoreCounterpart((prev) => [...prev, counterpart])
      //   }
      // })
      if (data?.resData?.data?.case_counterparts?.length > 1) {
        setFirstCounterpart(data.resData.data.case_counterparts[0])
        setMoreCounterpart(data.resData.data.case_counterparts.slice(1))
      } else {
        setFirstCounterpart(data.resData.data.case_counterparts[0])
        setMoreCounterpart([])
      }
    }
  })

  const regulationsData = useQuery({
    queryKey: ['workspace-regulation-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getRegulationList(token)
      const regulations = res?.resData?.data?.filter?.((item) => {
        return item
      })
      return regulations
    }
  })

  const casesStatus = useQuery({
    queryKey: ['workspace-cases-status-list', detailData?.regulation_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getStatusListDetailCase(token, detailData?.regulation_id)
      return res
    }
  })

  async function handleSubmitCaseModal(data) {
    try {
      await updateCase(token, data, detailData?.id)
      refetch()
      showSuccessMessage('Case Edited')
      queryClient.invalidateQueries(['case-detail'])
    } catch (error) {
      showErrorMessage(error?.response?.data?.errors?.[0]?.message)
    }
  }

  function handleSubmitRenameChat(data) {
    renameChat.mutate(data)
  }

  function handleSubmitRenameDocument(data) {
    renameDocument.mutate(data)
  }

  const lisData = useQuery({
    queryKey: ['data-list', filterState, tableState, tabTable],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tabTable === 0) {
        return await getChatListDetailCase(token, filterState, tableState, caseId)
      } else if (tabTable === 1) {
        return await getDocumentListDetailCase(token, filterState, tableState, caseId)
      }
    },
    onSuccess: (data) => {
      if (tabTable === 0) {
        setChatList(data.resData.data)
        setTableState((prev) => ({
          ...prev,
          total: data.resData.meta.total,
          limit: data.resData.meta.limit,
          page: data.resData.meta.page
        }))
      } else if (tabTable === 1) {
        setDocumentList(data.resData.data)
        setTableState((prev) => ({
          ...prev,
          total: data.resData.meta.total,
          limit: data.resData.meta.limit,
          page: data.resData.meta.page
        }))
      }
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    }
  })
  useQuery({
    queryKey: ['refetch-document-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return await getDocumentListDetailCase(token, filterState, tableState, caseId)
    },
    onSuccess: (data) => {
      setDocumentList(data.resData.data)
      setTableState((prev) => ({
        ...prev,
        total: data.resData.meta.total,
        limit: data.resData.meta.limit,
        page: data.resData.meta.page
      }))
    },
    onError: (err) => {
      const obj = errorObj(err)
      showErrorMessage(obj.resData.errors[0].message)
    },
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    enabled: documentList.findIndex((doc) => doc.status === 'IN_PROGRESS') !== -1
  })

  useQuery({
    queryKey: ['status-list', detailData?.regulation_id],
    refetchOnWindowFocus: false,
    queryFn: async () => await getStatusListDetailCase(token, detailData?.regulation_id),

    onSuccess: (data) => {
      if (Array.isArray(data.resData.data)) {
        const newData = data.resData.data.map((status) => ({
          value: status.id,
          label: (
            <div className="flex items-center">
              <div
                className={`w-2 h-2 rounded-full mr-2`}
                style={{
                  backgroundColor: `#${status.hex_code.toUpperCase()}`
                }}
              />
              <span className="text-black text-[13px] font-semibold">{t(status.name)}</span>
            </div>
          )
        }))
        setStatusList(newData)
      }
    }
  })

  const updateStatus = useMutation({
    mutationKey: ['update-status'],
    mutationFn: async (value) => await updateStatusDetailCase(token, caseId, value),
    onSuccess: () => {
      showSuccessMessage('Status updated')
    }
  })
  const handleChangeStatus = (value) => {
    setStatusValue(value)
    updateStatus.mutate(value)
  }

  const handleChangeSort = (e) => {
    if (e.sortable) {
      // if tablestate.sort === created_at change to updated_at and vice versa
      if (e.sortField === 'name') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'name' ? '-name' : 'name'
        }))
      } else if (e.sortField === 'created_at') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'created_at' ? '-created_at' : 'created_at'
        }))
      } else if (e.sortField === 'filename') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'filename' ? '-filename' : 'filename'
        }))
      } else if (e.sortField === 'document_total') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'document_total' ? '-document_total' : 'document_total'
        }))
      } else if (e.sortField === 'note_total') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'note_total' ? '-note_total' : 'note_total'
        }))
      } else if (e.sortField === 'chat_total') {
        setTableState((prev) => ({
          ...prev,
          sort: prev.sort === 'chat_total' ? '-chat_total' : 'chat_total'
        }))
      }
    }
  }
  const handleChangeTab = (value) => {
    setTabTable(value)
    setTableState(initialTableState)
    setFilterState(initialFilterState)
  }
  const onRowsPerPageChange = (event) => {
    setTableState((prev) => ({ ...prev, limit: event.target.value }))
  }
  const onPageChange = (event, newPage) => {
    setTableState((prev) => ({ ...prev, page: newPage + 1 }))
  }

  useEffect(() => {
    if (infoHeaderRef?.current?.clientHeight !== infoHeaderLayout) {
      setInfoHeaderLayout(infoHeaderRef?.current?.clientHeight ?? 0)
    }
  }, [infoHeaderRef?.current !== null, seeMore])

  const optionsPopover = [
    {
      title: 'Edit',
      callback: () =>
        setModalCase((prev) => {
          return { ...prev, show: true }
        }),
      role: [ROLE_ADMIN, ROLE_USER]
    },
    {
      title: 'Delete case',
      callback: openDeleteCaseModal.bind(this, detailData),
      role: [ROLE_ADMIN]
    },
    {
      title: 'Export notes',
      callback: openExportNotesModal.bind(this, detailData),
      role: [ROLE_ADMIN, ROLE_USER]
    }
  ]

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    )
  }
  function handleSearch(value) {
    setTableState((prev) => ({ ...prev, search: value }))
  }
  const onSearch = (e) => {
    handleSearch(e.target.value)
  }
  const debouncedResults = useMemo(() => {
    return debounce(onSearch, 300)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const handleImageError = (event) => {
    event.target.src = 'https://via.placeholder.com/150?text=Image+Not+Found'
    event.target.alt = 'Image not found'
  }
  const checkImageURL = (url) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = url

      img.onload = () => resolve(false) // Gambar berhasil dimuat, return false (tidak error)
      img.onerror = () => resolve(true) // Gambar gagal dimuat, return true (error)
    })
  }
  useEffect(() => {
    const checkImages = async () => {
      if (detailData?.users) {
        // Pastikan detailData dan detailData.users sudah terdefinisi
        const statuses = await Promise.all(
          detailData.users.map(async (user) => {
            const isError = await checkImageURL(user.avatar_url)
            return {
              ...user,
              isError
            }
          })
        )
        const statusMap = statuses.reduce((acc, { avatar_url, isError }) => {
          acc[avatar_url] = isError
          return acc
        }, {})
        setImageStatus(statusMap)
      }
    }

    checkImages()
  }, [detailData?.users])
  return (
    <div className="grid grid-cols-1" style={{ height: 200 }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="w-full col-span-10 cursor-pointer rounded-3xl border border-solid border-[#E3E3E8] bg-white">
          <div ref={infoHeaderRef}>
            <div className="flex justify-between px-6 items-end border-solid border-t-0 border-x-0 border-[#E3E3E8] py-4 mb-6">
              <div>
                <Breadcrumbs>
                  <Typography.Text
                    className="text-black font-normal text-[14px]"
                    onClick={() => navigate('/cases')}>
                    {t('Cases')}
                  </Typography.Text>
                </Breadcrumbs>
                <Typography.Text className="text-black font-semibold text-[30px]">
                  {detailData?.name}
                </Typography.Text>
              </div>
              <div className="flex items-center">
                <Typography.Text className="text-black font-normal text-[14px]">
                  {t('Edited')} {moment(detailData?.updated_at).format('DD/MM/yyyy')}
                </Typography.Text>
                <Popover
                  content={
                    <div className="w-[128px] h-full flex flex-col text-black">
                      {optionsPopover.map(
                        (option, index) =>
                          option.role.includes(platformData?.role?.toUpperCase()) && (
                            <div
                              key={index}
                              onClick={option.callback}
                              className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                              {t(option.title)}
                            </div>
                          )
                      )}
                    </div>
                  }
                  trigger="click"
                  placement="bottomRight"
                  arrow={false}>
                  <MoreVertOutlined
                    fontSize="small"
                    className={`cursor-pointer hover:text-[#0049EE] ml-4`}
                  />
                </Popover>
              </div>
            </div>
            <div className="flex gap-x-2 px-6">
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <DataUsage fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('Status')}
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    {statusList.length === 0 ? (
                      '-'
                    ) : (
                      <ConfigProvider
                        theme={{
                          components: {
                            Select: {
                              colorBorder: '#EDEDEF',
                              borderRadius: 60,
                              paddingContentVertical: 0,
                              padding: 0,
                              paddingContentVerticalLG: 0,
                              paddingContentVerticalSM: 0,
                              paddingMD: 0,
                              controlHeight: 28,
                              controlItemBgActive: '#F5F5F5'
                            }
                          }
                        }}>
                        <Select
                          className="status-case-page"
                          value={statusValue !== 0 ? statusValue : statusList?.[0]?.value}
                          popupClassName="status-case-page-popup"
                          // disabled
                          suffixIcon={<KeyboardArrowDown fontSize="small" className="text-black" />}
                          style={{
                            width: 200
                          }}
                          onChange={(value) => handleChangeStatus(value)}
                          options={statusList}
                        />
                      </ConfigProvider>
                    )}
                  </div>
                </div>

                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <Balance fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('Regulation')}
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    <Typography.Text className="text-black font-semibold">
                      {detailData?.regulation?.title ? t(detailData?.regulation?.title) : '-'}
                    </Typography.Text>
                  </div>
                </div>

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <CalendarToday
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('Starting date')}
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <Typography.Text className="text-black font-semibold">
                        {detailData?.starting_date
                          ? moment(detailData?.starting_date).format('DD/MM/yyyy')
                          : '-'}
                      </Typography.Text>
                    </div>
                  </div>
                )}

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Numbers fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('Register No.')}
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <div>
                        {detailData?.case_numbers?.length > 0
                          ? detailData?.case_numbers.map((data, index) => {
                              return (
                                <Typography.Text className="text-black font-semibold" key={index}>
                                  {index === 0 ? '' : ', '}
                                  {data.number}
                                </Typography.Text>
                              )
                            })
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PersonOutline
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('Client')}
                    </Typography.Text>
                  </div>
                  <div className={`flex-[2_2_0%] ${!seeMore ? 'flex items-center' : ''}`}>
                    <Typography.Text className="text-black font-semibold">
                      {firstClient?.first_name} {firstClient?.last_name}
                      {firstClient?.nickname && !seeMore ? ',' : ''}{' '}
                      {firstClient?.nickname === '' ? '' : `(${firstClient?.nickname})`}
                      <span>
                        {moreClient.length !== 0 && !seeMore && (
                          <Typography.Text className="text-black font-semibold mx-1">
                            {' '}
                            and{' '}
                          </Typography.Text>
                        )}
                      </span>
                      <span>
                        {moreClient.length !== 0 && !seeMore && (
                          <Tooltip
                            arrow
                            title={
                              <div>
                                {moreClient.map((client, index) => {
                                  return (
                                    <Typography.Text key={index} className="block">
                                      {client.first_name} {client.last_name}
                                      {client.nickname ? ',' : ''}{' '}
                                      {client.nickname === '' ? '' : `(${client.nickname})`}
                                    </Typography.Text>
                                  )
                                })}
                              </div>
                            }>
                            <Typography.Text className="text-[#0049EE] font-semibold">
                              {' '}
                              {moreClient.length} {t('others')}
                            </Typography.Text>
                          </Tooltip>
                        )}
                      </span>
                    </Typography.Text>
                    {moreClient.length !== 0 && seeMore && (
                      <Typography.Text className="text-black font-semibold">
                        ,{' '}
                        {moreClient
                          .map((client, index) => {
                            return (
                              <span key={index}>
                                {client.first_name} {client.last_name}{' '}
                                {client.nickname === '' ? '' : `(${client.nickname})`}
                              </span>
                            )
                          })
                          // add comma for each client
                          .reduce((prev, curr) => [prev, ', ', curr])}
                      </Typography.Text>
                    )}
                  </div>
                </div>

                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PersonOutline
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">
                      {t('Counterpart')}
                    </Typography.Text>
                  </div>
                  <div className={`flex-[2_2_0%] ${!seeMore ? 'flex items-center' : ''}`}>
                    <Typography.Text className="text-black font-semibold">
                      {firstCounterpart?.first_name} {firstCounterpart?.last_name}
                      {firstCounterpart?.nickname && !seeMore ? ',' : ''}{' '}
                      {firstCounterpart?.nickname === '' ? '' : `(${firstCounterpart?.nickname})`}
                      <span>
                        {moreCounterpart.length !== 0 && !seeMore && (
                          <Typography.Text className="text-black font-semibold mx-1">
                            {' '}
                            and{' '}
                          </Typography.Text>
                        )}
                      </span>
                      <span>
                        {moreCounterpart.length !== 0 && !seeMore && (
                          <Tooltip
                            arrow
                            title={
                              <div>
                                {moreCounterpart.map((counterpart, index) => {
                                  return (
                                    <Typography.Text key={index} className="block">
                                      {counterpart.first_name} {counterpart.last_name}
                                      {counterpart.nickname ? ',' : ''}{' '}
                                      {counterpart.nickname === ''
                                        ? ''
                                        : `(${counterpart.nickname})`}
                                    </Typography.Text>
                                  )
                                })}
                              </div>
                            }>
                            <Typography.Text className="text-[#0049EE] font-semibold">
                              {' '}
                              {moreCounterpart.length} others
                            </Typography.Text>
                          </Tooltip>
                        )}
                      </span>
                    </Typography.Text>
                    {moreCounterpart.length !== 0 && seeMore && (
                      <Typography.Text className="text-black font-semibold">
                        ,{' '}
                        {moreCounterpart
                          .map((counterpart, index) => {
                            return (
                              <span key={index}>
                                {counterpart.first_name} {counterpart.last_name}{' '}
                                {counterpart.nickname === '' ? '' : `(${counterpart.nickname})`}
                              </span>
                            )
                          })
                          .reduce((prev, curr) => [prev, ', ', curr])}
                      </Typography.Text>
                    )}
                  </div>
                </div>

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Gavel fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('Judge/Court')}
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <div>
                        {detailData?.case_judges?.length > 0
                          ? detailData?.case_judges.map((data, index) => {
                              return (
                                <Typography.Text className="text-black font-semibold" key={index}>
                                  {index === 0 ? '' : ', '}
                                  {data.name}
                                </Typography.Text>
                              )
                            })
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <AccountCircle
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        {t('Staff')}
                      </Typography.Text>
                    </div>

                    <div className="flex-[2_2_0%] relative">
                      <div className="flex flex-wrap -space-x-1 ">
                        {detailData?.users?.length > 0
                          ? detailData?.users.map((data, index) => {
                              const isError = imageStatus[data.avatar_url]
                              return (
                                <Tooltip key={index} arrow title={`${data?.name} ${data?.surname}`}>
                                  {data.avatar_url && !isError ? (
                                    <img
                                      src={data.avatar_url}
                                      className="w-6 h-6 rounded-full"
                                      alt={`Avatar ${index}`}
                                    />
                                  ) : (
                                    <div
                                      className={`w-6 h-6 rounded-full bg-[#0049EE] flex items-center justify-center text-white font-semibold text-[10px]`}>
                                      {data?.name[0]} {data?.surname[0]}
                                    </div>
                                  )}
                                </Tooltip>
                              )
                            })
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center pl-6">
              <Typography.Text
                className="text-[#828282] font-normal mr-4 hover:pointer"
                onClick={() => setSeeMore((prevState) => !prevState)}>
                {seeMore ? t('See less') : t('See more')}
              </Typography.Text>
              <div className="h-[0.6px] flex-1 bg-[#828282]" />
            </div>
          </div>

          <div className="px-6 mt-4">
            <div className="flex items-center justify-between mb-2">
              <Tabs
                textColor="inherit"
                sx={{ minHeight: 0 }}
                TabIndicatorProps={{
                  style: { borderRadius: 20, height: 5, backgroundColor: '#0049EE' }
                }}
                value={tabTable}
                onChange={(_, newValue) => {
                  handleChangeTab(newValue)
                }}>
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    marginRight: 2,
                    padding: 0
                  }}
                  label={t('Chat')}
                  value={0}
                />
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    padding: 0
                  }}
                  label={t('Documents')}
                  value={1}
                />
              </Tabs>

              {tabTable === 0 && (
                <div className="flex items-center">
                  {openSearch ? (
                    <SearchInput variant="outlined" onSearch={debouncedResults} />
                  ) : (
                    <Search
                      className={`cursor-pointer hover:text-[#0049EE]`}
                      onClick={openSearchBox}
                    />
                  )}

                  <div onClick={openFilterChatModal} className="flex items-center mx-4">
                    <FilterListOutlined
                      className={`cursor-pointer hover:text-[#0049EE] ${
                        getFilterCount() !== 0 ? 'text-[#0049EE]' : ''
                      }`}
                    />
                    {getFilterCount() !== 0 && (
                      <div
                        className={`w-4 h-4 rounded-full bg-[#0049EE] flex items-center justify-center text-white font-semibold `}>
                        {getFilterCount()}
                      </div>
                    )}
                  </div>

                  <CustomButton onClick={openNewChatModal} className="w-[190px]">
                    <div className="font-normal flex items-center ">
                      {t('New chat')}
                      <Add className="ml-1" />
                    </div>
                  </CustomButton>
                </div>
              )}

              {tabTable === 1 && (
                <div className="flex items-center">
                  {openSearch ? (
                    <SearchInput variant="outlined" onSearch={debouncedResults} />
                  ) : (
                    <Search
                      className={`cursor-pointer hover:text-[#0049EE]`}
                      onClick={openSearchBox}
                    />
                  )}
                  <div onClick={openFilterChatModal} className="flex items-center mx-4">
                    <FilterListOutlined
                      className={`cursor-pointer hover:text-[#0049EE] ${
                        getFilterCount() !== 0 ? 'text-[#0049EE]' : ''
                      }`}
                    />
                    {getFilterCount() !== 0 && (
                      <div
                        className={`w-4 h-4 rounded-full bg-[#0049EE] flex items-center justify-center text-white font-semibold `}>
                        {getFilterCount()}
                      </div>
                    )}
                  </div>

                  <CustomButton onClick={() => uploadRef.current?.click()} className="w-[250px]">
                    <div className="font-normal flex items-center">
                      {t('Add document ')}
                      <Add className="ml-1" />
                    </div>
                  </CustomButton>
                </div>
              )}
            </div>
            <CustomTabPanel value={tabTable} index={0}>
              <ListChat
                height={layoutInfo - infoHeaderLayout}
                data={chatList}
                onClickRow={navigateToChat}
                onRename={openRenameChatModal}
                rowsPerPage={tableState.limit}
                page={tableState.page}
                total={tableState.total}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                handleSort={handleChangeSort}
                onDelete={handleOpenDeleteTableChat}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabTable} index={1}>
              <ListDocument
                height={layoutInfo - infoHeaderLayout}
                data={documentList}
                rowsPerPage={tableState.limit}
                page={tableState.page}
                total={tableState.total}
                onExport={handleExportDocument}
                navigateToDoc={navigateToDoc}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                handleSort={handleChangeSort}
                onRename={openRenameDocumentModal}
                onRetry={onRetryDocument}
                goToTheChatFromDocument={goToTheChatFromDocument}
                onDelete={handleOpenDeleteTableDocument}
                onDownload={handleDownloadDocument}
              />
            </CustomTabPanel>
          </div>
        </div>
        <NotesContainer
          setTabNote={setTabNote}
          tabNote={tabNote}
          height={layoutInfo}
          caseId={caseId}
          caseName={detailData?.name}
        />
      </div>
      {filterChatModal.show && (
        <ChatFilterModal
          show={filterChatModal}
          data={filterChatModal.data}
          startDate={filterState.startDate}
          endDate={filterState.endDate}
          filterDataType={filterDataType}
          onClose={closeFilterChatModal}
          onSubmit={handleSubmitFilter}
          cancelFilter={cancelFilter}
        />
      )}
      {modalCase.show && (
        <CaseModal
          show={modalCase.show}
          data={detailData ?? {}}
          regulations={regulationsData?.data}
          casesStatus={casesStatus?.data?.resData?.data}
          type={modalCase.type}
          onClose={() =>
            setModalCase((prev) => {
              return {
                ...prev,
                show: false
              }
            })
          }
          onSubmit={handleSubmitCaseModal}
        />
      )}
      {renameModal.show && (
        <RenameModal
          title={renameModal.title}
          onClose={closeRenameModal}
          show={renameModal.show}
          data={dataRename}
          onSubmit={renameModal.onClick}
        />
      )}
      {exportNotesModal.show && (
        <ExportNotesModal
          show={exportNotesModal.show}
          onExport={handleExportNotes}
          closeModal={closeExportNotesModal}
          isLoading={exportNotesMutation.isLoading}
        />
      )}
      {deleteCaseModal.show && (
        <DeleteCaseModal
          show={deleteCaseModal.show}
          onDelete={handleDeleteCase}
          closeModal={closeDeleteCaseModal}
          isLoading={deleteCaseMutation.isLoading}
        />
      )}
      {showDeleteTable && (
        <DeleteCaseDetailTableItem
          show={showDeleteTable}
          onDelete={handleDeleteTable}
          closeModal={handleCloseDeleteTable}
          item={deleteItem}
          isLoading={deleteItem === 'chat' ? deletingChat.isLoading : deletingDocument.isLoading}
        />
      )}
      {newChatModal && (
        <NewChatModal
          data={{ caseName: detailData?.name, caseId: detailData?.id }}
          show={newChatModal}
          closeModal={closeNewChatModal}
        />
      )}
      {documentModal.show && (
        <AddDocumentModal
          data={{ caseName: detailData?.name, caseId: detailData?.id }}
          files={documentModal.files}
          show={documentModal.show}
          refetch={lisData.refetch}
          closeModal={() =>
            setDocumentModal((prev) => {
              return {
                ...prev,
                show: false,
                files: []
              }
            })
          }
        />
      )}
      <input
        className={`hidden`}
        type="file"
        id="file"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,.pdf,text/plain"
        ref={uploadRef}
        style={{ display: 'none' }}
        multiple
        onChange={(e) => {
          if (e.target.files.length > 0) {
            setDocumentModal({
              show: true,
              files: e.target.files
            })
          }
        }}
      />
    </div>
  )
}

export default CasesPage
