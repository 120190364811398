import { AutoFixNormalOutlined, InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { IcSend } from 'assets/icons'
import { CONST_QUESTION, CONST_SHORTCUT } from 'constant'
import useTCPP from 'hooks/useTCPP'
import React, { useEffect, useRef, useState } from 'react'
import TCModal from '../TCModal'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
const INITIAL_STATE = {
  type: CONST_QUESTION,
  message: ''
}

const shortcutChat = [
  {
    titleButton: 'Summary (FIRAC)',
    tooltipTitle: "Brief explanation of the document's main contents",
    value: 'Please analyze the following legal case using the FIRAC method'
    // value: "Make a brief explanation of all of the attached document's main contents"
  },
  {
    titleButton: 'Specifications',
    tooltipTitle:
      'Extracting the most relevant data of the document (client, crime, judge, etc...)',
    value: 'Extract the most relevant data of all of the attached document'
  },
  {
    titleButton: 'Timeline',
    tooltipTitle: 'Extracting all dates in chronological order',
    value: 'Extract all dates inside all attached document in chronological order'
  },
  {
    titleButton: 'Regulation',
    tooltipTitle: 'Extracting all criminal/civil articles in the document',
    value: 'Extract all criminal/civil articles that is related to the attached documents'
  },
  {
    titleButton: 'Keyword',
    tooltipTitle: 'Identificating a set of relevant keywords within the document',
    value: 'Identify all the relevant keywords within all the attached document'
  }
]
const InputChat = ({ onSubmit = () => {}, isLoading }) => {
  const textAreaRef = useRef()
  const { t } = useTranslation()
  const { onLogout } = useAuth()
  const { handleSubmitTcModal, tcModal, acceptTC, isPPFetching, isTCFetching, openTcModal } =
    useTCPP()
  const [showShortcut, setShowShortcut] = useState(false)
  function toggleShortcut() {
    setShowShortcut((prev) => !prev)
  }
  const [keyword, setKeyword] = useState(INITIAL_STATE)
  const isFocus = useRef(false)
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter' && !event.shiftKey && isFocus.current) {
        event.preventDefault()
        onSubmit({
          ...keyword,
          message: textAreaRef.current.innerText
        })
        textAreaRef.current.innerText = ''
        setKeyword(INITIAL_STATE)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyword, onSubmit])

  function handleChangeKeyword(value) {
    setKeyword((prev) => ({ ...prev, message: value }))
  }
  function handleShortcut(value, title) {
    const titleuncapitalize = title.charAt(0).toLowerCase() + title.slice(1)
    onSubmit({ type: CONST_SHORTCUT, message: value, title: titleuncapitalize })
  }

  return (
    <>
      {/* Input Wrapper */}
      <div className="flex gap-1 relative overflow-hidden px-3 flex-col border border-solid border-neutral-300 bg-white rounded-lg">
        {/* shortcut Wrapper */}
        <div
          className={`h-11 border-solid border-0 border-b border-[#E3E3E8] py-2 w-full gap-3 duration-300 ease-in-out ${
            showShortcut ? 'flex' : 'hidden'
          }`}>
          {shortcutChat.map((shortcut, index) => (
            <div
              className="flex justify-center group items-center gap-2 p-3 border border-solid border-[#828282] rounded bg-[#F7F7F8] hover:border-[#0049EE] hover:bg-[#EBF1FF] cursor-pointer"
              key={`shortcut-${index}-button`}
              onClick={handleShortcut.bind(this, t(shortcut.value), shortcut.titleButton)}>
              <span className="font-semibold text-[#595961] group-hover:text-[#0049EE]">
                {t(shortcut.titleButton)}
              </span>
              <Tooltip title={t(shortcut.tooltipTitle)} arrow>
                <InfoOutlined
                  className="text-[#595961] group-hover:text-[#0049EE]"
                  fontSize="small"
                />
              </Tooltip>
            </div>
          ))}
        </div>
        {/* Input Field Wrapper */}
        <div className="w-full flex gap-2">
          {/* Show Shortcut */}
          <div className="flex justify-center items-center">
            <div
              className={`${
                showShortcut ? 'bg-[#0049EE] hover:bg-[#EDEDEF]' : 'bg-[#EDEDEF] hover:bg-[#0049EE]'
              } h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer group `}
              onClick={toggleShortcut}>
              <AutoFixNormalOutlined
                className={`${
                  showShortcut
                    ? 'text-white group-hover:text-[#828282]'
                    : 'text-[#828282] group-hover:text-white'
                }`}
              />
            </div>
          </div>
          {/* Input */}
          <p
            ref={textAreaRef}
            autoFocus
            disabled={isLoading}
            onFocus={() => (isFocus.current = true)}
            onBlur={() => (isFocus.current = false)}
            value={keyword.message}
            onChange={(e) => {
              e.preventDefault()
              handleChangeKeyword(textAreaRef.current.innerText)
            }}
            contentEditable={true}
            suppressContentEditableWarning={true}
            className="w-full outline-none h-14 rounded-lg border-none border-0 text-neutral-700 overflow-scroll items-center pt-5 px-1 "
            data-placeholder="Message Chat"
            type="text"></p>
          {/* Button Wrapper */}
          <div className="flex justify-center items-center">
            <div
              className="bg-[#0049EE] h-9 w-9 p-2 rounded flex justify-center items-center cursor-pointer hover:bg-[#2e5fd1]"
              onClick={() => {
                if (!isLoading) {
                  onSubmit({
                    ...keyword,
                    message: textAreaRef.current.innerText
                  })
                  textAreaRef.current.innerText = ''
                  setKeyword(INITIAL_STATE)
                }
              }}>
              <IcSend className={'h-5'} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-[11px] flex justify-center items-center pt-1 text-[#828282] text-center w-full">
        LegalAI can make mistakes, so please check its answers.{' '}
        <span className="underline" onClick={openTcModal}>
          Your privacy policy
        </span>
      </div>
      {tcModal.show && (
        <TCModal
          isFetching={isPPFetching || isTCFetching}
          isLoading={acceptTC.isLoading}
          pp={tcModal.pp}
          tc={tcModal.tc}
          show={tcModal.show}
          onClose={onLogout}
          onSubmit={handleSubmitTcModal}
          onLogout={onLogout}
        />
      )}
    </>
  )
}

export default InputChat
