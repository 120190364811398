/* eslint-disable react/no-children-prop */
import { Refresh } from '@mui/icons-material'
import _ from 'lodash'
import React, { useState } from 'react'
import { Spin } from 'antd'
import RegulationDialogReference from './RegulationDialogReference'
import { postRegulationReferences } from 'api'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { LogoMinimized } from 'assets/images'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import { visit } from 'unist-util-visit'
const RegulationBotResponseItem = ({
  text = '',
  isLastIndex = true,
  onAddNote = () => {},
  onRegenerate = () => {},
  isLoading,
  item
}) => {
  const token = useSelector((state) => state.user.access_token)
  const [openDialogReference, setOpenDialogReference] = useState(false)
  const [dataRegulationReferences, setDataRegulationReferences] = useState([])

  const handleCloseDialogReference = () => {
    setOpenDialogReference(false)
  }
  const postDataRegulationReferences = useMutation({
    mutationKey: ['postRegulationReferences'],
    mutationFn: async (data) => await postRegulationReferences(token, data),
    onSuccess: (res) => {
      setDataRegulationReferences(res?.resData?.data)
    }
  })

  const handleOpenDialogReference = () => {
    setOpenDialogReference(true)
    postDataRegulationReferences.mutate(item?.sources)
  }
  // Custom plugin to filter out image nodes
  const removeImages = () => (tree) => {
    visit(tree, 'image', (node, index, parent) => {
      parent.children.splice(index, 1)
    })
  }
  const TableWrapper = (props) => {
    return (
      <div className="w-full overflow-x-auto text-sm">
        <table {...props} />
      </div>
    )
  }
  return (
    <div className="flex gap-2 w-full">
      <div className="flex justify-center items-start w-10">
        <div className="rounded-full overflow-hidden h-6 w-6 bg-[#0049EE]">
          <img src={LogoMinimized} alt="avatarImage" className="object-contain w-full h-full p-1" />
        </div>
      </div>
      <div className="flex w-full h-full flex-col items-start justify-start">
        <span className="text-base font-bold">Legal AI</span>
        <div className="w-full h-full">
          {isLoading && isLastIndex ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              <ReactMarkdown
                className="leading-6"
                children={text}
                rehypePlugins={[rehypeKatex, rehypeRaw]}
                remarkPlugins={[remarkGfm, remarkMath, removeImages]}
                components={{
                  table: TableWrapper,
                  // Custom rendering for <pre> and <code>
                  pre: ({ ...props }) => (
                    <pre
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        overflow: 'auto',
                        maxWidth: '100%'
                      }}
                      {...props}
                    />
                  ),
                  code: ({ ...props }) => (
                    <code
                      style={{
                        // display: 'block',
                        padding: '0.5em',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '3px'
                      }}
                      {...props}
                    />
                  ) // Override the table rendering with scrollable wrapper
                }}
              />
              {item?.sources ? (
                <span className="text-[#0049EE] cursor-pointer" onClick={handleOpenDialogReference}>
                  {'["]'}
                </span>
              ) : null}
            </>
          )}
        </div>
        {isLastIndex && !isLoading ? (
          <div className="flex  items-center pt-2 text-[#828282] ">
            <div
              onClick={() => {
                _.throttle(() => {
                  return onRegenerate()
                }, 1000)()
              }}>
              <Refresh className="cursor-pointer hover:text-[#5c5b5b]" />
            </div>
          </div>
        ) : null}
      </div>
      <RegulationDialogReference
        open={openDialogReference}
        handleClose={handleCloseDialogReference}
        item={dataRegulationReferences}
      />
    </div>
  )
}

export default RegulationBotResponseItem
