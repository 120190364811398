import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'
import { CONST_QUESTION, CONST_SHORTCUT } from 'constant'

export const getChatHistory = (token, chatId) => {
  return apiGet({ token: token, url: `${urls.chats.chats}/${chatId}` })
}

export const postChatMessage = (token, chatId, data) => {
  return apiPost({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.message}`,
    data: {
      chat_id: parseInt(chatId),
      question: data?.type == CONST_QUESTION ? data?.message : null,
      shortcut: data?.type == CONST_SHORTCUT ? data?.title : null
    }
  })
}

export const postRegenerateMessageChat = (token, chatId) => {
  return apiPost({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.regenerateMessage}`,
    data: {
      chat_id: chatId,
      type: 'chat'
    }
  })
}
export const getReferenceRegulationChat = (token, keyword, caseId) => {
  return apiGet({
    token: token,
    url: `${urls.chats.chats}/${urls.chats.reference}?query=${keyword}&case_id=${caseId}`
  })
}
