import React, { useState } from 'react'
import { Typography } from 'antd'
import { Box, Button } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import ModalUser from 'components/molecules/user/ModalUser'
import SelectUserFields from 'components/molecules/user/SelectUserFields'
import TextFields from 'components/molecules/TextFields'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import { ADD_CASE, DELETE_CASE, EXPORT_CASE_NOTES } from 'constant'
import SelectCheckboxCaseFields from 'components/molecules/user/cases/SelectCheckboxCaseFields'
import CustomButton from 'components/atoms/CustomButton'
import { showSuccessMessage } from 'utils/showMessage'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { getDetailCase, getStatusListDetailCase, getUserList } from 'api'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const schema = z.object({
  caseName: z.string().min(1, { message: 'Case name is required' }),
  regulation: z.string().min(1, { message: 'Regulation is required' }),
  clients: z.array(
    z.object({
      itemId: z.number(),
      clientName: z.string().min(1, { message: 'Client name is required' }),
      clientLastName: z.string().min(1, { message: 'Client last name is required' }),
      clientNickname: z.string().min(1, { message: 'Client nickname is required' })
    })
  ),
  counterparts: z.array(
    z.object({
      itemId: z.number(),
      counterpartName: z.string().min(1, { message: 'Counterpart name is required' }),
      counterpartLastName: z.string().min(1, { message: 'Counterpart last name is required' }),
      counterpartNickname: z.string().min(1, { message: 'Counterpart nickname is required' })
    })
  ),
  startDate: z.string().min(1, { message: 'Proceeding date is required' }),
  status: z.string().min(1, { message: 'Status is required' }),
  proceedingNumbers: z.array(
    z.object({
      itemId: z.number(),
      number: z.string()
    })
  ),
  judges: z.array(
    z.object({
      itemId: z.number(),
      name: z.string()
    })
  ),
  usersWithAccess: z.array(z.number()).optional()
})

const CaseModal = ({
  show,
  data,
  regulations,
  type = ADD_CASE,
  onClose,
  onSubmit = () => {},
  message
}) => {
  const { t } = useTranslation()
  const token = useSelector((state) => state?.user?.access_token)
  const companyId = useSelector((state) => state?.user?.profile?.company?.id)
  const role = useSelector((state) => state.user.role)
  const [regulationId, setRegulationId] = useState()
  const usersData = useQuery({
    queryKey: ['cases-companies-user-list', show],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let filter = {
        role: ['USER', 'EDITOR'],
        typeDate: undefined,
        startDate: undefined,
        endDate: undefined,
        company: { value: companyId }
      }
      let tableState = {
        total: 0,
        page: 1,
        limit: 'all',
        search: '',
        sort: 'name'
      }
      const res = await getUserList(token, 1, filter, tableState)
      return res
    }
  })

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    control,
    resetField
  } = useForm({
    defaultValues: {
      caseName: '',
      regulation: '',
      clients: [
        {
          itemId: 0,
          clientLastName: '',
          clientNickname: '',
          clientName: ''
        }
      ],
      counterparts: [
        {
          itemId: 0,
          counterpartLastName: '',
          counterpartNickname: '',
          counterpartName: ''
        }
      ],
      startDate: null,
      status: '',
      proceedingNumbers: [{ itemId: 0, number: '' }],
      judges: [{ itemId: 0, name: '' }],
      usersWithAccess: []
    },
    resolver: zodResolver(schema)
  })

  useQuery({
    queryKey: ['cases-detail-case-modal', show, data?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let ressult = {}
      if (data?.id) {
        const res = await getDetailCase(token, data?.id)
        ressult = res
      }
      return ressult
    },
    onSuccess: (res) => {
      setValue('caseName', res?.resData?.data?.name ?? '')
      setValue(
        'clients',
        res?.resData?.data?.case_clients?.map?.((item) => {
          return {
            itemId: item?.id,
            clientLastName: item?.last_name,
            clientNickname: item?.nickname,
            clientName: item?.first_name
          }
        }) ?? [
          {
            itemId: 0,
            clientLastName: '',
            clientNickname: '',
            clientName: ''
          }
        ]
      )
      setValue(
        'counterparts',
        res?.resData?.data?.case_counterparts?.map?.((item) => {
          return {
            itemId: item?.id,
            counterpartLastName: item?.last_name,
            counterpartNickname: item?.nickname,
            counterpartName: item?.first_name
          }
        }) ?? [
          {
            itemId: 0,
            counterpartLastName: '',
            counterpartNickname: '',
            counterpartName: ''
          }
        ]
      )
      setValue(
        'judges',
        res?.resData?.data?.case_judges?.length > 0
          ? res?.resData?.data?.case_judges?.map((item) => {
              return {
                itemId: item?.id,
                name: item?.name
              }
            })
          : [{ itemId: 0, name: '' }]
      )
      setValue(
        'proceedingNumbers',
        res?.resData?.data?.case_numbers?.length > 0
          ? res?.resData?.data?.case_numbers?.map((item) => {
              return {
                itemId: item?.id,
                number: item?.number
              }
            })
          : [{ itemId: 0, number: '' }]
      )

      setValue(
        'status',
        res?.resData?.data?.case_status_id !== 0 ? data?.case_status_id?.toString() : ''
      )
      setValue(
        'startDate',
        res?.resData?.data?.starting_date !== null
          ? moment(data?.starting_date).format('MM-DD-YYYY')
          : null
      )
      setValue('usersWithAccess', res?.resData?.data?.user_ids ?? [])
      setRegulationId(res?.resData?.data?.regulation_id?.toString() ?? '')
      setValue('regulation', res?.resData?.data?.regulation_id?.toString() ?? '')
    },
    enabled: show && data?.id !== undefined
  })

  const casesStatus = useQuery({
    queryKey: ['workspace-cases-status-list', regulationId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getStatusListDetailCase(token, regulationId)
      return res
    },
    enabled: show
  })

  const cliensField = useFieldArray({ control, name: 'clients' })
  const counterpartsField = useFieldArray({ control, name: 'counterparts' })
  const proceedingNumbersField = useFieldArray({ control, name: 'proceedingNumbers' })
  const judgesField = useFieldArray({ control, name: 'judges' })

  function closeModal() {
    reset()
    onClose()
  }
  function processForm(data) {
    let date = ''
    if (data?.startDate) {
      date = data?.startDate?.split('-')
      date = `${date[2]}-${date[0]}-${date[1]}`
    }
    let newCounterparts = []
    let newClients = []
    let newJudges = []
    let newProceedingNumbers = []
    newClients = data?.clients?.map((client) => {
      return {
        first_name: client?.clientName,
        last_name: client?.clientLastName,
        nickname: client?.clientNickname
      }
    })
    newCounterparts = data?.counterparts?.map((counterpart) => {
      return {
        first_name: counterpart?.counterpartName,
        last_name: counterpart?.counterpartLastName,
        nickname: counterpart?.counterpartNickname
      }
    })
    newJudges = data?.judges
    newProceedingNumbers = data?.proceedingNumbers
    if (newJudges?.[0]?.name === '' && newJudges?.length === 1) {
      newJudges = []
    }
    if (newProceedingNumbers?.[0]?.number === '' && newProceedingNumbers?.length === 1) {
      newProceedingNumbers = []
    }
    let body = {
      name: data?.caseName,
      regulation_id: parseInt(data?.regulation),
      case_status_id: parseInt(data?.status),
      starting_date: date,
      clients: newClients,
      counterparts: newCounterparts,
      numbers: newProceedingNumbers,
      judges: newJudges,
      user_ids: data?.usersWithAccess
    }
    onSubmit(body)
    closeModal()
  }

  if (type === DELETE_CASE || type === EXPORT_CASE_NOTES) {
    return (
      <ModalUser
        centered={false}
        onClose={closeModal}
        show={show}
        width={444}
        titleComponent={
          <div className="font-semibold text-xl">
            {type === DELETE_CASE ? t('Delete case') : t('Export notes')}
          </div>
        }>
        <div className="flex flex-col justify-between gap-5">
          <Typography.Text>{message}</Typography.Text>
          <div className="flex justify-end gap-2">
            <CustomButton onClick={closeModal} buttonColor="secondary">
              {t('Cancel')}
            </CustomButton>
            <CustomButton
              buttonType="submit"
              variant="contained"
              onClick={() => {
                showSuccessMessage(type === DELETE_CASE ? t('Case deleted') : t('Notes exported'))
                closeModal()
              }}>
              {type === DELETE_CASE ? t('Delete') : t('Export')}
            </CustomButton>
          </div>
        </div>
      </ModalUser>
    )
  }

  return (
    <ModalUser
      show={show}
      modalTheme={{
        fontWeightStrong: '500',
        paddingContentHorizontalLG: 15
      }}
      centered={false}
      titleComponent={
        <span className="mx-4">{type === ADD_CASE ? t('New case') : t('Edit case')}</span>
      }
      onClose={closeModal}>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col w-full">
        <div className="h-[400px] overflow-y-scroll px-4">
          <div className="flex gap-4 mb-4 justify-center items-center">
            <TextFields
              isRequired
              control={control}
              label={t('Case name')}
              name={'caseName'}
              errors={errors}
            />
            <SelectUserFields
              control={control}
              isRequired
              label={t('Regulation')}
              name={'regulation'}
              onChange={(e) => {
                if (e.target.value !== regulationId) {
                  setRegulationId(() => {
                    resetField('status')
                    return e.target.value
                  })
                }
              }}
              errors={errors}
              options={
                regulations?.map?.((regulation) => {
                  return {
                    value: regulation?.id?.toString(),
                    label: t(regulation?.title)
                  }
                }) ?? []
              }
            />
          </div>
          <div className="flex gap-4 mb-4 justify-center items-center ">
            <DatePickerUserFields
              control={control}
              label={t('Proceeding starting date')}
              name={'startDate'}
              errors={errors}
              isRequired
            />
            <SelectUserFields
              control={control}
              label={t('Status')}
              name={'status'}
              errors={errors}
              isRequired
              options={
                casesStatus?.data?.resData?.data?.map?.((status) => {
                  return {
                    value: status?.id?.toString(),
                    label: (
                      <div className="flex items-center">
                        <div
                          className={`w-2 h-2 rounded-full mr-3`}
                          style={{ backgroundColor: `#${status?.hex_code}` }}
                        />
                        <span className="font-normal text-black">{t(status?.name)}</span>
                      </div>
                    )
                  }
                }) ?? []
              }
            />
          </div>
          {cliensField?.fields?.map((field, index) => (
            <div
              className="flex gap-4 mb-4 justify-center items-center"
              key={`client-${field?.id}`}>
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Client name')}
                name={`clients[${index}].clientName`}
                errors={errors?.clients?.[index]?.clientName}
              />
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Client last name')}
                name={`clients[${index}].clientLastName`}
                errors={errors?.clients?.[index]?.clientLastName}
              />
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Client nickname')}
                name={`clients[${index}].clientNickname`}
                errors={errors?.clients?.[index]?.clientNickname}
              />
              {cliensField?.fields?.length - 1 === index ? (
                <AddCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    cliensField.insert(index, {
                      itemId: 0,
                      clientLastName: '',
                      clientNickname: '',
                      clientName: ''
                    })
                  }}
                />
              ) : (
                <RemoveCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    cliensField.remove(index)
                  }}
                />
              )}
            </div>
          ))}
          {counterpartsField?.fields?.map((field, index) => (
            <div
              className="flex gap-4 mb-4 justify-center items-center"
              key={`client-${field?.id}`}>
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Counterpart name')}
                name={`counterparts[${index}].counterpartName`}
                errors={errors?.counterparts?.[index]?.counterpartName}
              />
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Counterpart last name')}
                name={`counterparts[${index}].counterpartLastName`}
                errors={errors?.counterparts?.[index]?.counterpartLastName}
              />
              <TextFields
                control={control}
                inArray={true}
                isRequired
                label={t('Counterpart nickname')}
                name={`counterparts[${index}].counterpartNickname`}
                errors={errors?.counterparts?.[index]?.counterpartNickname}
              />
              {counterpartsField?.fields?.length - 1 === index ? (
                <AddCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    counterpartsField.insert(index, {
                      itemId: 0,
                      counterpartLastName: '',
                      counterpartNickname: '',
                      counterpartName: ''
                    })
                  }}
                />
              ) : (
                <RemoveCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    counterpartsField.remove(index)
                  }}
                />
              )}
            </div>
          ))}
          <div className="mb-4 mt-8">
            <Typography.Text className="font-semibold">{t('Optional information')}</Typography.Text>
          </div>

          {proceedingNumbersField?.fields?.map((field, index) => (
            <div
              className="flex gap-4 mb-4 justify-center items-center"
              key={`proceeding-number-${field?.id}`}>
              <TextFields
                isRequired={false}
                control={control}
                inArray={true}
                label={t('Proceeding number')}
                name={`proceedingNumbers[${index}].number`}
                errors={errors?.proceedingNumbers?.[index]?.number}
              />
              {proceedingNumbersField?.fields?.length - 1 === index ? (
                <AddCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    proceedingNumbersField.insert(index, {
                      itemId: 0,
                      action: 'create',
                      number: ''
                    })
                  }}
                />
              ) : (
                <RemoveCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    proceedingNumbersField.remove(index)
                  }}
                />
              )}
            </div>
          ))}
          {judgesField?.fields?.map((field, index) => (
            <div className="flex gap-4 mb-4 justify-center items-center" key={`judge-${field?.id}`}>
              <TextFields
                isRequired={false}
                control={control}
                inArray={true}
                label={t('Judge/Court')}
                name={`judges[${index}].name`}
                errors={errors?.judges?.[index]?.name}
              />
              {judgesField?.fields?.length - 1 === index ? (
                <AddCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    judgesField.insert(index, {
                      itemId: 0,
                      name: ''
                    })
                  }}
                />
              ) : (
                <RemoveCircleOutline
                  className="text-[#595961] hover:cursor-pointer hover:text-black ml-1"
                  onClick={() => {
                    judgesField.remove(index)
                  }}
                />
              )}
            </div>
          ))}
          <div className="my-4">
            <Typography.Text className="font-semibold">
              {t('Permission management')}
            </Typography.Text>
          </div>
          <div className="flex gap-4 mb-4 justify-center items-center ">
            <SelectCheckboxCaseFields
              control={control}
              label={t('Users with access to this case')}
              name={'usersWithAccess'}
              disabled={role === 'User' && type !== ADD_CASE}
              errors={errors}
              options={
                usersData?.data?.resData?.data?.map?.((user) => {
                  return {
                    value: user?.id,
                    label: `${user?.name} ${user?.surname}`
                  }
                }) ?? []
              }
            />
          </div>
        </div>
        {/* Button */}
        <div className="flex justify-end gap-2 mt-5">
          <Button
            onClick={closeModal}
            type="button"
            variant="outlined"
            sx={{
              borderColor: '#888',
              fontSize: '14px',
              textTransform: 'none',
              color: '#888'
            }}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isValid}
            type="submit"
            variant="contained"
            sx={{
              // mt: 4,
              backgroundColor: '#0049EE',
              fontSize: '14px',
              textTransform: 'none'
            }}>
            {t('Confirm')}
          </Button>
        </div>
      </Box>
    </ModalUser>
  )
}

export default CaseModal
