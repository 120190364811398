import React, { useEffect, useRef, useState } from 'react'
import {
  ArrowForward,
  ChatBubbleOutline,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const ListDocument = ({
  height,
  data = [],
  onEdit = () => {},
  onExport = () => {},
  onDelete = () => {},
  goToTheChatFromDocument = () => {},
  onRowsPerPageChange = () => {},
  page = 1,
  rowsPerPage = 9,
  total = 0,
  navigateToDoc = () => {},
  onPageChange = () => {},
  handleSort = () => {},
  onRetry = () => {},
  onRename = () => {},
  onDownload = () => {}
}) => {
  const { t } = useTranslation()
  const paginationRef = useRef()
  const [tableHeight, setTableHeight] = useState(height ?? 0)

  useEffect(() => {
    if (paginationRef?.current) {
      setTableHeight(height - paginationRef?.current?.clientHeight)
    }
  }, [paginationRef?.current !== null, height])

  const optionsPopover = [
    {
      title: 'Rename',
      callback: onRename,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'SUCCEEDED']
    },
    {
      title: 'Retry',
      callback: onRetry,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'FAILED']
    },
    {
      title: 'Download',
      callback: onDownload,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'SUCCEEDED']
    },
    {
      title: 'Export',
      callback: onExport,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'SUCCEEDED']
    },
    {
      title: 'Open as chat',
      callback: goToTheChatFromDocument,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'SUCCEEDED']
    },
    {
      title: 'Delete',
      callback: onDelete,
      role: ['admin', 'editor'],
      status: ['IN_PROGRESS', 'SUCCEEDED', 'FAILED', 'UPLOAD_FAILED']
    }
  ]
  function handleClickMenu(callback, row) {
    return callback(row)
  }

  const statusColors = {
    IN_PROGRESS: '#22C55E',
    SUCCEEDED: '#00B47E',
    FAILED: '#F5550B',
    UPLOAD_FAILED: '#B91C1C'
  }

  const statusBgColors = {
    IN_PROGRESS: '#FFFBEB',
    SUCCEEDED: '#F0FDF4',
    FAILED: '#FFFBEB',
    UPLOAD_FAILED: '#FEF2F2'
  }

  const statusNames = {
    IN_PROGRESS: 'In Progress',
    SUCCEEDED: 'Success',
    FAILED: 'Need to Retry',
    UPLOAD_FAILED: 'Need to Re-upload'
  }
  const columns = [
    {
      name: t('Name'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <div
            className="w-2 h-2 rounded-full"
            style={{
              backgroundColor: statusColors[row?.status]
            }}
          />
          <Tooltip title={row?.filename}>
            {/* <span className="line-clamp-1">{row?.filename} <span className='border border-blue-600 border-solid w-5 h-5'>New</span></span>  */}
            <span className="line-clamp-1">{row?.filename} </span>
          </Tooltip>
          {row?.is_new && (
            <span className="border border-[#0049EE] border-solid w-[45px] h-[20px] rounded-l-xl rounded-r-xl text-[#0049EE] text-bold text-center">
              {t('New')}
            </span>
          )}
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      sortField: 'filename'
    },
    {
      name: t('Opened chats'),
      cell: (row) => (
        <div className="flex gap-2 items-center mr-5 px-4 w-full justify-end">
          {row?.chat_total} <ChatBubbleOutline className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.chat_total,
      sortable: true,
      width: '150px',
      sortField: 'chat_total'
    },
    {
      name: t('Opened notes'),
      cell: (row) => (
        <div className="flex gap-2 items-center px-4 mr-5 w-full justify-end">
          {row?.note_total} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
        </div>
      ),
      selector: (row) => row?.note_total,
      sortable: true,
      sortField: 'note_total',
      width: '150px'
    },
    {
      name: t('Loading date'),
      selector: (row) => moment(row?.created_at).format('DD/MM/yyyy'),
      sortable: true,
      sortField: 'created_at',
      width: '120px'
    },
    {
      name: t('Status'),
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <span
            style={{
              color: statusColors[row?.status],
              backgroundColor: statusBgColors[row?.status]
            }}
            className={`py-1 px-2 border border-[#F0FDF4] border-solid rounded-l-xl rounded-r-xl text-bold text-center `}>
            {t(statusNames[row?.status])}
          </span>
        </div>
      ),
      selector: (row) => row?.status,
      sortable: false,
      width: '170px'
    },
    {
      name: '',
      cell: (row) => (
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={() => navigateToDoc(row)}>
          <ArrowForward className="cursor-pointer hover:text-[#0049EE]" />
        </div>
      ),
      width: '60px',
      sortable: false
    },
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.map(
                (option, index) =>
                  option.role.includes('admin') &&
                  option.status.includes(row?.status) && (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickMenu(option.callback, row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {t(option.title)}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} />
        </Popover>
      ),
      width: '60px',
      sortable: false
    }
  ]

  return (
    <div className="w-full">
      <div>
        <Table
          tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
          hidePagination
          columns={columns}
          data={data}
          headStyles={{ backgroundColor: 'white' }}
          onSort={(e) => handleSort(e)}
          sortServer={true}
          sortIcon={
            <div className="text-[5px] ml-1">
              <div>▲</div>
              <div className="transform rotate-180">▲</div>
            </div>
          }
          fixedHeader
        />
      </div>
      <div className="pr-6 mt-1" ref={paginationRef}>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[20]}
          page={page - 1}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
    </div>
  )
}

export default ListDocument
